<script lang="ts">
	export let width: string | number = '1em';
</script>

<svg
	class="inline"
	{width}
	height={width}
	viewBox="0 0 24 24"
	fill="none"
	xmlns="http://www.w3.org/2000/svg"
>
	<mask id="path-1-inside-1_218_30" fill="white">
		<rect x="0.75" width="22.5" height="16.0174" rx="1" />
	</mask>
	<rect
		x="0.75"
		width="22.5"
		height="16.0174"
		rx="1"
		stroke="currentColor"
		stroke-width="3"
		mask="url(#path-1-inside-1_218_30)"
	/>
	<rect
		x="0.364032"
		y="18.5657"
		width="23.2719"
		height="0.728065"
		rx="0.364032"
		stroke="currentColor"
		stroke-width="0.728065"
	/>
	<rect
		x="-0.134457"
		y="-0.50199"
		width="0.744577"
		height="5.09261"
		rx="0.372288"
		transform="matrix(-0.872333 -0.488912 0.511171 -0.859479 7.30368 23.0008)"
		stroke="currentColor"
		stroke-width="0.744577"
	/>
	<rect
		x="-0.515063"
		y="-0.137958"
		width="0.744577"
		height="5.0881"
		rx="0.372288"
		transform="matrix(-0.872333 0.488912 -0.511171 -0.859479 16.7639 23.2411)"
		stroke="currentColor"
		stroke-width="0.744577"
	/>
</svg>
