<script lang="ts">
	export let width: string | number = '1em';
</script>

<svg
	class="inline"
	{width}
	height={width}
	viewBox="0 0 26 26"
	fill="none"
	xmlns="http://www.w3.org/2000/svg"
>
	<path
		d="M1 6.86666V1.8C1 1.35817 1.35817 1 1.8 1H9.53336C9.97518 1 10.3334 1.35817 10.3334 1.8V6.86666C10.3334 7.30849 9.97518 7.66666 9.53336 7.66666H1.8C1.35817 7.66666 1 7.30849 1 6.86666Z"
		stroke="currentColor"
		stroke-width="1.5"
	/>
	<path
		d="M15.6667 24.1999V19.1333C15.6667 18.6914 16.0249 18.3333 16.4668 18.3333H24.2001C24.642 18.3333 25.0001 18.6914 25.0001 19.1333V24.1999C25.0001 24.6418 24.642 24.9999 24.2001 24.9999H16.4668C16.0249 24.9999 15.6667 24.6418 15.6667 24.1999Z"
		stroke="currentColor"
		stroke-width="1.5"
	/>
	<path
		d="M15.6667 13.5333V1.8C15.6667 1.35817 16.0249 1 16.4668 1H24.2001C24.642 1 25.0001 1.35817 25.0001 1.8V13.5333C25.0001 13.9752 24.642 14.3333 24.2001 14.3333H16.4668C16.0249 14.3333 15.6667 13.9752 15.6667 13.5333Z"
		stroke="currentColor"
		stroke-width="1.5"
	/>
	<path
		d="M1 24.1999V12.4666C1 12.0248 1.35817 11.6666 1.8 11.6666H9.53336C9.97518 11.6666 10.3334 12.0248 10.3334 12.4666V24.1999C10.3334 24.6418 9.97518 24.9999 9.53336 24.9999H1.8C1.35817 24.9999 1 24.6418 1 24.1999Z"
		stroke="currentColor"
		stroke-width="1.5"
	/>
</svg>
