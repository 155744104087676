import DashboardIcon from '$lib/components/icons/DashboardIcon.svelte';
import GearIcon from '$lib/components/icons/GearIcon.svelte';
import LiveIcon from '$lib/components/icons/LiveIcon.svelte';
import WhiteboardIcon from '$lib/components/icons/WhiteboardIcon.svelte';
import { ScopeType } from '$lib/graphql/generated';
import type { ComponentType, SvelteComponentTyped } from 'svelte';

export const appAbsolutePath = '/app/' as const;

interface NavLink {
	/** The path from the root of the domain. */
	href: `${typeof appAbsolutePath}${string}`;
	/** The path relative to {@link appAbsolutePath}. */
	appHref: string;
	title: string;
	/**
	 * If defined, the user should have at least one of the required scope of the list to have access to the link.
	 * (Only used for display and navigation purposes, authorization is handled by the server)
	 */
	accessScopes: ScopeType[] | null;
	/**
	 * If defined, the user should have at least one of the required scope of the set to have access to the link.
	 * (Only used for display and navigation purposes, authorization is handled by the server)
	 */
	accessScopesSet: Set<ScopeType> | null;
	/**
	 * A component constructor to build the icon (used on mobile).
	 */
	icon: ComponentType<SvelteComponentTyped>;
	/** If true, the link should be hidden on mobile. */
	hideOnMobile?: boolean;
}

/** App-wide links, with necessary scopes for each page. */
export const appLinks: NavLink[] = [
	{
		appHref: 'whiteboard',
		title: 'Whiteboard',
		icon: WhiteboardIcon,
		accessScopes: [ScopeType.MiddleofficeDesk],
	},
	{
		appHref: 'live',
		title: 'Live View',
		icon: LiveIcon,
		accessScopes: [ScopeType.LiveView],
	},
	{
		appHref: 'dashboard',
		title: 'Dashboard',
		icon: DashboardIcon,
		accessScopes: [ScopeType.Dashboard],
		hideOnMobile: true,
	},
	{
		appHref: 'backoffice',
		title: 'Backoffice',
		icon: GearIcon,
		accessScopes: [
			ScopeType.MiddleofficeDesk,
			ScopeType.DataExportMiddleoffice,
			ScopeType.UserAdmin,
		],
	},
]
	// Compute `href` from `appHref`, and `accessScopesSet` from `accessScopes`
	.map(({ appHref, accessScopes = null, ...link }) => ({
		...link,
		appHref,
		accessScopes,
		accessScopesSet: accessScopes ? new Set(accessScopes) : null,
		href: `${appAbsolutePath}${appHref}`,
	}));
